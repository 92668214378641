import React from 'react';
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import '../pages/blog.css'
import SEO from '../components/SEO'
import Hero from '../components/hero/hero'
import siteConfig from '../../data/siteConfig'
import Wrapper from '../components/wrapper'

function Tags(props) {
  const posts = props.data.allMarkdownRemark.edges;
  const { tag } = props.pageContext;
  const title = siteConfig.blogTitle

  return (
    <Layout>
      <SEO title="Blog Tags" />
      <Hero
        heroImg={siteConfig.siteCover}
        title={title}
      />
      <Wrapper>
      <h1>{`Available posts  in ${tag}`}</h1>
      <div className="tags">
        {
          posts.map(({ node }, i) => (
            <Link to={node.fields.slug} key={i} >
              {node.frontmatter.title}
            </Link>
          ))
        }
      </div>
      </Wrapper>
    </Layout>
  )
}

export default Tags;


export const query = graphql`

 query TagsQuery($tag: String!) {
allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
}
`
